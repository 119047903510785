<template>
	<div class="main-content">
		<ktv-breadcrumb :title="$t('User Dashboard', 'User Dashboard')" :page="$t('List', 'List')"
			:folder="$t('Dashboard', 'Dashboard')"
		/>
		<ktv-table id="smelist" :columns="columns" :is-loading="isLoading" :rows="rows" :total-rows="totalRecords"
			:mode="modeTable" :filter="false" :search-custom-enabled="true"
			:search-placeholder="$t('Search by Dashboard Name', 'Search by Dashboard Name')"
			@on-column-filter="onColumnFilter" @on-page-change="onPageChange" @on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter" @on-sort-change="onSortChange"
		>
			<template #columns="{ props }">
				{{ $t(props.column.label, props.column.label) }}
			</template>
			<template #actionleft>
				<ktv-button :text="$t('Add', 'Add')" icon="add" color="green-light" @click="openForm('Add')" />
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button' && props.row.DashID">
					<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<span>
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="gotoDetail(props.row.BoardID)">
							<img :src="iconview" class="imgicon"> {{ $t('View', 'View') }}
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('Update', props.row)">
							<img :src="iconedit" class="imgicon"> {{ $t('Update', 'Update') }}
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="deleteData(props.row.DashID)">
							<img :src="icondelete" class="imgicon"> {{ $t('Delete', 'Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</span>
			</template>
		</ktv-table>
	</div>
</template>
<script>
	import { KtvTable, KtvButton, KtvModal } from "@/components"
	import { mapActions } from "vuex"

	export default {
		metaInfo: {
			title: "User Dashboard",
		},
		// eslint-disable-next-line vue/no-unused-components
		components: { KtvTable, KtvButton, KtvModal },
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				iconcognito: require("@/assets/images/icon/iconcognito.png"),
				iconreset: require("@/assets/images/icon/iconcognito.png"),
				/* eslint-disable global-require */
				modalReset: false,
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Actions",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Dashboard Name",
						field: "DashName",
						thClass: "text-left",
					},
					{
						label: "Deskripsi",
						field: "Description",
						thClass: "text-left",
					},
					{
						label: "Board ID",
						field: "BoardID",
						thClass: "text-left",
					},
					{
						label: "Author",
						field: "LastModifiedBy",
						thClass: "text-left",
					},
					{
						label: "Tanggal Pembaruan",
						field: "DateCreated",
						thClass: "text-left",
					},
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						// Name: "",
						// DashName: "",
						"BoardID": "",
						"DashName": ""
					},
					sort: [
						{
							field: "",
							type: "",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
			// reset: {
			// 	Username: "",
			// 	Password: "",
			// 	PasswordConfirm: ""
			// }
			}
		},
		mounted() {
			this.loadItems()
		},
		methods: {
			...mapActions({
				pushData: "USERDASHBOARD/pushData",
				removeData: "USERDASHBOARD/deleteData"
			}),
			deleteData(DashID) {
				// let payload = {
				// 	DashID: DashID
				// }
				this.$swal({
					title: "Are you sure?",
					text: "Data will be deleted!",
					type: "question",
					showCancelButton: true,
					confirmButtonText: "Delete",
					showLoaderOnConfirm: true,
					preConfirm: () => {
						// return this.removeData(payload)
						// 	.then((res) => {
						// 		return res
						// 	})
						// 	.catch((error) => {
						// 		this.$swal.hideLoading()
						// 		return error
						// 	})
						this.$http
							.Delete(this.serverParams, this.$urlApi.userDashboard.delete + "?DashID=" + DashID)
							// eslint-disable-next-line no-unused-vars
							.then((response) => {
								this.loadItems()
							// return response
							})
							.catch((error) => {
								this.isLoading = false
								this.$swal("Error!", error.response.error_massage, "error")
							})
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
				}).then((res) => {
					// console.log('ress', res)
					if (res.value) {
						this.$swal("Success!", "Data has been deleted.", "success").then(() => {
							this.loadItems()
						})
					} else {
						this.$swal("Error!", "Data failed to be deleted.", "error")
					}
				})
			},
			gotoDetail(BoardID) {
				this.$router.push("/usrdboard/detail?BoardID=" + BoardID)
			},
			openForm(opsi, items = []) {
				let data = {
					opsiDisplay: opsi,
				}
				// console.log('data', data);
				this.pushData(data)
				if (opsi === 'Add') {
					this.$router.push("/usrdboard/form?DashID=0")
				} else if (opsi === 'Update') {
					this.$router.push("/usrdboard/form?DashID=" + items.DashID)
				}
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1,
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { BoardID: "", DashName: event },
					currentPage: 1,
					rowPerpage: 10,
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.userDashboard.list)
					.then((response) => {
						// console.log('res', response)
						this.isLoading = false
						if (response.success) {
							this.totalRecords = response.results.total
							this.rows = response.results.data
							if (Object.keys(this.rows).length < 10 && Object.keys(this.rows).length !== 0) {
								this.rows = response.results.data
								for (let i = 0; Object.keys(this.rows).length < 10; i++) {
									this.rows.push({});
								}
							}
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			}
		},
	}
</script>
